.page-footer {
  position: fixed;
  bottom: 20px;
  width: calc(100% - 240px - 69px) !important;
  background-color: red;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 2.5rem;
  border-radius: 2px;
  border: 1px solid #ededed;
  background: white;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06),
    0px -2px 4px 0px rgba(0, 0, 0, 0.04);
  padding: 16px 24px;
  z-index: 10;

  &.no-title {
    justify-content: flex-end;
  }

  @media only screen and (max-width: 992px) {
    width: calc(100% - 2.8rem);
  }
}

@primary-color: #F9DF64;