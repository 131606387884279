.vui-container-wrapper {
  padding: 30px;
  margin-left: 250px;

  @media only screen and (max-width: 992px) {
    padding: 15px;
    margin-left: 0px;
  }
}

@primary-color: #F9DF64;